export default function (component) {
  let selectedElement = null;

  function showModal() {
    const toolbarButton = document.querySelector('button.tox-tbtn.tox-tbtn--enabled[aria-haspopup="true"][aria-expanded="true"]');
    if (toolbarButton) {
      toolbarButton.click();
    }

    component.showWidgetModal();
  }

  /* Add a button that opens a window */
  component.editor.ui.registry.addButton('widgets', {
    text: 'Виджеты',
    onAction: function () {
      showModal();
    }
  });

  function unselect(except = null) {
    if (component.editor.dom.doc) {
      const nodes = component.editor.dom.doc.querySelectorAll('widget[data-mce-selected="1"]');

      if (nodes.length > 0) {
        for (const widget of nodes.values()) {
          if (!except || widget !== except) {
            widget.removeAttribute('data-mce-selected');
          }
        }
      }
    }

    component.clearEditableWidgetDom()
  }

  component.editor.on('click', (e) => {
    let widget = null

    if (e.target && e.target.tagName.toLowerCase() === 'widget') {
      widget = e.target
    } else if(e.target.closest('widget')) {
      widget = e.target.closest('widget');
    }

    console.log({widget});

    if (widget) {
      widget.setAttribute('data-mce-selected', '1');
      // Для Alert data-mce-selected не выставляется из-за contenteditable="true". Решаем своим атрибутом
      widget.setAttribute('data-selected', '1');

      selectedElement = widget;
    } else {
      selectedElement = null;
    }

    unselect(e.target);
  });

  component.editor.on('input', () => {
    unselect();
  });

  component.editor.on('blue', () => {
    unselect();
  });

  component.editor.on('focusout', () => {
    unselect();
  });

  component.editor.ui.registry.addContextToolbar('widget', {
    predicate: function (node) {
      return node.nodeName.toLowerCase() === 'widget';
    },
    items: 'editWidget removeWidget',
    position: 'node',
    scope: 'node'
  });

  component.editor.ui.registry.addButton('editWidget', {
    icon: 'edit-block',
    onAction: function () {
      if (selectedElement) {
        component.editableWidgetDom = selectedElement;
        document.querySelector('.tox-pop')?.remove();
        component.showWidgetModal();
      }
    }
  });

  component.editor.ui.registry.addButton('removeWidget', {
    icon: 'remove',
    onAction: function () {
      if (selectedElement) {
        selectedElement.remove();
        document.querySelector('.tox-pop')?.remove();
      }
    }
  });

  /* Return the metadata for the help plugin */
  return {
    getMetadata: function () {
      return {
        name: 'Example plugin',
        url: 'http://exampleplugindocsurl.com'
      };
    }
  };
}
